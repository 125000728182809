import { useNavigate } from 'react-router-dom';

import { SubmitFormType } from 'shared/types';
import { routeBuilder, useToast } from 'shared/utils';
import { ViewButton } from 'features/ViewButton';
import { DeleteActivity } from 'features/activites/DeleteActivity';
import { ActivityForm } from 'features/activites/ActivityForm';
import { ActivityFormType, IActivity } from 'entities/tracking/model';
import { useUpdateActivity } from 'entities/tracking/api';
import { convertActivityFormData } from './convertActivityFormData';
import { useTranslation } from 'react-i18next';
import useStore from '../../shared/store';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  activity: IActivity;
  companyId: string;
}

const EditActivity = ({ activity, companyId }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id, credits, companyID, topicIDs, ...defaultData } = activity;
  const navigation = useNavigate();
  const { isLoading, mutateAsync } = useUpdateActivity(_id, companyId);
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();

  const setFilesToConnect = useStore((state) => state.setFilesToConnect);
  const filesToConnect = useStore((state) => state.filesToConnect);
  const { deleteActivityPrivilege } = LayoutPrivilege();

  const viewPath = routeBuilder.activities.view.getPath(_id);

  const handleSubmit = async (
    data: ActivityFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutateAsync({
        ...convertActivityFormData(data),
        attachmentIDs: [
          ...filesToConnect
            .filter(
              (file) =>
                file.parent === `edit-activity-form-${_id}-${companyId}`,
            )
            .map((filesToConnect) => filesToConnect._id),
        ],
      });

      setFilesToConnect(
        filesToConnect.filter(
          (file) => file.parent !== `edit-activity-form-${_id}-${companyId}`,
        ),
      );

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.activities.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', { item: t('activity') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('activity') }),
      });
    }
  };

  return (
    <ActivityForm
      companyId={companyId}
      formId={`edit-activity-form-${_id}-${companyId}`}
      defaultValues={{
        ...defaultData,
        topics: topicIDs?.reduce((acc, topicId) => {
          return { ...acc, [topicId]: { value: true, _id: topicId } };
        }, {}),
      }}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      additionalActionButtons={
        <>
          <ViewButton onClick={() => navigation(viewPath)} />
          {activity.active && deleteActivityPrivilege && (
            <DeleteActivity
              companyId={companyId}
              activityId={_id}
            />
          )}
        </>
      }
    />
  );
};

export { EditActivity };
