import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { ErrorBoundary, Select, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';
import { useGetAuthorityUsers } from 'entities/user/api/useGetAuthorityUsers';
import { useGetMyUser } from 'entities/user/api';
import { ISelectFieldProps } from 'shared/components/Form/SelectField';
import { useEffect, useRef } from 'react';

interface IProps {
    companyId: string;
    name?: string;
    inputProps?: Partial<ISelectFieldProps>;
    isEditing?: boolean;
}

type OptionType = { value: string; label: string };

const defaultName = 'trackedBy[0]';

const TrackedByFieldThrowable = ({ companyId, name = defaultName, ...restProps }: IProps) => {
    const { t } = useTranslation('commonFields');
    const userQuery = useGetMyUser(true);
    const {
        watch,
        setValue,
        formState: { isSubmitting },
    } = useFormContext();
    const { isLoading, data } = useGetAuthorityUsers(
        companyId,
        {
            sort: [{ name: 'name.lastname', sortDirection: 'asc' }],
        },
        { useErrorBoundary: false },
    );

    // Options for the select field include the null value and the users from the company
    const options = [
        { value: null, label: t('Tracked By') },
        ...(data?.data
            ? data.data.map((user) => {
                return {
                    value: user._id,
                    label: `${user.name.firstname} ${user.name.lastname}`,
                };
            })
            : []),
    ];

  const value = watch(name);
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            const id = userQuery.data?.data?._id;
            if (!value && id && options.find(({ value }) => value === id)) {
                setValue(name, id);
            }
        }
    }, [userQuery.data?.data?._id, options, setValue, name, value]);

    // Make sure the options are set before we render the select field
    if (!options) {
        return null;
    }

    // Render the select field
    return (
        <FormField label={`${t('Tracked By')}:`}>
            <Select
                data-testid={name}
                inputId={name}
                isDisabled={isSubmitting}
                value={options?.find(
                    (option) => (option as OptionType).value === value,
                )}
                onChange={(value) => setValue(name, (value as OptionType).value)}
                options={options}
                isLoading={isLoading}
                placeholder={t('Select one')}
                {...restProps.inputProps}
            />
        </FormField>
    );
};

const TrackedByField = ({ companyId, name, ...restProps }: IProps) => {
    const { t } = useTranslation('commonFields');

    return (
        <ErrorBoundary
            fallback={() => (
                <FormField label={`${t('Tracked By')}:`}>
                    <SelectField name={name || defaultName} {...restProps.inputProps} />
                </FormField>
            )}
        >
            <TrackedByFieldThrowable
                companyId={companyId}
                name={name}
                inputProps={restProps.inputProps}
                isEditing={restProps.isEditing}
            />
        </ErrorBoundary>
    );
};

export { TrackedByField };
